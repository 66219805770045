import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store"; // Import RootState with explicit type

const API_BASE_URL = `${process.env.REACT_APP_SERVER_BACKEND_URL}`; // Replace with your API base URL
// const API_BASE_URL = "http://localhost:8001"; // Replace with your API base URL

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const token = state.auth.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Content-Type", "application/json");
      headers.set("Access-Control-Allow-Origin", "*");
      headers.set("ngrok-skip-browser-warning", "any"); //only when using ngrok
    },
  }),
  endpoints: (builder) => ({
    getJobList: builder.query<any, number>({
      query: (pageNo) => `/job-listing?pageNo=${pageNo}`,
    }),
  }),
});
export const { useGetJobListQuery } = api;
