import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { WithChildrenProps } from "../types/generalTypes";
import { deleteToken, readToken } from "../services/LocalStorageService";
import { store } from "../store/store";
const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  // const token = readToken();
  const state = store.getState();
  const token = state.auth.token;
  const navigate = useNavigate();

  window.addEventListener("storage", (e) => {
    if (e.key === "auth" && e.oldValue && !e.newValue) {
      if (token) {
        deleteToken();
      }
      navigate("/sign-in");
    }
  });

  return token ? <>{children}</> : <Navigate to="/sign-in" replace />;
};

export default RequireAuth;
