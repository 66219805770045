import {
  IScheduleInterUpdate,
  IScheduleInterview,
} from "@app/interfaces/interviewInterface";
import axios from "axios";

const InterviewService = {
  getAllInterview: (pageNo: number, startDate: any, endDate: any) => {
    let url: string = `/interview?pageNo=${pageNo}`;
    if (startDate) {
      url += `&startDate=${startDate}`;
    }
    if (endDate) {
      url += `&endDate=${endDate}`;
    }
    return axios.get(url);
  },

  getPreviousInterviewDetails: (jobId: any) => {
    let url = `candidate/search/email?email=${jobId}`;
    return axios.get(url);
  },

  getInterviewDetails: (interviewId: string) => {
    return axios.get(`/interview/${interviewId}`);
  },

  getInterviewStatus: () => {
    return axios.get("/get/interview/status");
  },

  getJobs: () => {
    return axios.get("/get/job");
  },

  getCandidateId: () => {
    return axios.get("/candidate/application");
  },

  scheduleInterview: (data: any) => {
    return axios.post("/interview", data);
  },

  updateInterview: (interviewId: string, interview: IScheduleInterUpdate) => {
    return axios.put(`/interview/${interviewId}`, interview);
  },

  deleteInterview: (interviewId: string) => {
    return axios.delete(`/interview/${interviewId}`);
  },

  searchCandidate: (candidateName: string) => {
    return axios.get(`candidate/search?name=${candidateName}`);
  },

  getInterviewRoundName: () => {
    return axios.get("/show/interview/round");
  },
};

export default InterviewService;
