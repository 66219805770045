import {
    IInterviewRound,
    IInterviewStatus,
} from "@app/interfaces/interviewInterface";
import InterviewService from "@app/services/InterviewService";
import {
    Button,
    Card,
    Col,
    DatePicker,
    Form,
    Input,
    Modal,
    Row,
    Select,
    TimePicker,
} from "antd";
import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { useNavigate } from "react-router-dom";
import { notificationController } from "@app/utills/toastNotification";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const CandidateScheduleInterview = (props: any) => {
    const { candidateId, candidateName, jobId, jobName, backUrl, emailId } = props
    const navigate = useNavigate();
    const [status, setStatus] = useState<IInterviewStatus[] | null>(null);
    const [statusData, setStatusData] = useState<IInterviewStatus[] | null>(null);
    const [roundName, setRoundName] = useState<IInterviewRound[] | null>(null);
    const [roundNameData, setRoundNameData] = useState<IInterviewRound[] | null>(
        null
    );
    const [interviewerName, setInterviewerName] = useState("");
    const [remark, setRemark] = useState("");
    const [interviewDate, setInterviewDate] = useState<string>("");
    const [interviewTime, setInterviewTime] = useState<string | null>(null);
    const [previousInterviewData, setPreviousInterviewData] = useState<any>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        fetchPreviousData()
    }, [])

    const fetchPreviousData = async () => {
        try {
            let responseData = await InterviewService.getPreviousInterviewDetails(emailId);
            setPreviousInterviewData(responseData.data.data);
        } catch (err: any) { }
    }

    const fetchRoundName = async () => {
        try {
            const RoundNameResponse = await InterviewService.getInterviewRoundName();
            const fetchedRoundName = RoundNameResponse?.data?.data;
            setRoundName(fetchedRoundName);
        } catch (err: any) {
            notificationController.error(err?.response?.data?.message);
            setRoundName([]);
        }
    };
    const fetchStatus = async () => {
        try {
            const statusResponse = await InterviewService.getInterviewStatus();
            const fetchedStatus = statusResponse?.data?.data;
            setStatus(fetchedStatus);
        } catch (err: any) {
            notificationController.error(err?.response?.data?.message);
            setStatus([]);
        }
    };
    useEffect(() => {
        fetchRoundName();
        fetchStatus();
    }, []);

    const onFinish = async () => {
        if (!interviewTime) {
            notificationController.error("Please enter the interview time!");
            return;
        }

        const data: any = {
            candidateId: candidateId,
            roundName: roundNameData && roundNameData?.length > 0 ? roundNameData[0] : "",
            interviewerName: interviewerName,
            status: statusData && statusData?.length > 0 ? statusData[0] : "",
            interviewTime: interviewTime,
            interviewDate: interviewDate,
            jobId: jobId,
        };
        if (remark != "") {
            data["remark"] = remark;
        }
        try {
            await InterviewService.scheduleInterview(data);
            notificationController.success("Interview Schedule Successfully");
            setTimeout(() => {
                navigate("/interview");
            }, 1000);
        } catch (err: any) {
            notificationController.error(err?.response?.data?.message);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        notificationController.error(errorInfo);
    };

    const showPreviousData =
        previousInterviewData &&
        previousInterviewData.length > 0 &&
        previousInterviewData[0]?.interview_range &&
        (previousInterviewData[0]?.interview_range).length > 0;

    return (
        <>
            <div style={{ cursor: "pointer" }} onClick={() => backUrl()}>
                <KeyboardBackspaceIcon />
            </div>
            <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="Schedule Interview"
                extra={
                    <>
                        {
                            showPreviousData ?
                                <Button type="primary" onClick={() => { setIsModalOpen(true) }}>
                                    Previous Scheduled Interviews
                                </Button>
                                : null
                        }
                    </>
                }
            >
                <div className="add-form">
                    <Form
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        layout="vertical"
                        className="row-col"
                    >
                        <Row>
                            <Col xs={24} md={8}>
                                <Form.Item
                                    className="candidateName"
                                    label="Candidate Name"
                                    name="candidateName"
                                >
                                    <Input
                                        style={{ width: "80%" }}
                                        defaultValue={candidateName}
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item
                                    className="selectJob"
                                    label="Select Job"
                                    name="selectJob"
                                >
                                    <Input
                                        style={{ width: "80%" }}
                                        defaultValue={jobName}
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item
                                    className="roundName"
                                    label="Round Name"
                                    name="roundName"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter the interview round!",
                                        },
                                    ]}
                                >
                                    <Select
                                        defaultValue={[]}
                                        size="large"
                                        style={{ width: "80%" }}
                                        onChange={(value) =>
                                            setRoundNameData(Array.isArray(value) ? value : [value])
                                        }
                                        placeholder="Add Round Name"
                                    >
                                        {roundName && Array.isArray(roundName) ? (
                                            roundName.map((item) => (
                                                <option key={item._id} value={item._id}>
                                                    {`${item.status}`}
                                                </option>
                                            ))
                                        ) : (
                                            <option>Choose...</option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} md={8}>
                                <Form.Item
                                    className="interviewerName"
                                    label="Interviewer Name"
                                    name="interviewerName"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter interviewer name!",
                                        },
                                        {
                                            pattern: new RegExp(/^\S.*[a-zA-Z\s]*$/g),
                                            message: "First character cannot be space",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Add Interviewer"
                                        style={{ width: "80%" }}
                                        onChange={(e) => setInterviewerName(e.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item
                                    className="status"
                                    label="Status"
                                    name="status"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter the interview status!",
                                        },
                                    ]}
                                >
                                    <Select
                                        defaultValue={[]}
                                        size="large"
                                        style={{ width: "80%" }}
                                        onChange={(value) =>
                                            setStatusData(Array.isArray(value) ? value : [value])
                                        }
                                        placeholder="Add Status"
                                    >
                                        {status && Array.isArray(status) ? (
                                            status.map((item) => (
                                                <option key={item._id} value={item._id}>
                                                    {`${item.status}`}
                                                </option>
                                            ))
                                        ) : (
                                            <option>Choose...</option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item
                                    className="date"
                                    label="Interview Date"
                                    name="date"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter the interview date!",
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        style={{ width: "80%", height: "42px" }}
                                        onChange={(date) => {
                                            return setInterviewDate(
                                                date ? dayjs(date).toISOString() : ""
                                            );
                                        }}
                                        disabledDate={(current) =>
                                            current && current < dayjs().startOf("day")
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} md={8}>
                                <Form.Item
                                    className="interviewTime"
                                    label="Interview Time"
                                    name="interviewTime"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter the interview time!",
                                        },
                                    ]}
                                >
                                    <TimePicker
                                        defaultValue={dayjs().startOf("day")}
                                        format="HH:mm:ss"
                                        style={{ width: "80%", height: "42px" }}
                                        onChange={(time: Dayjs | null, timeString: string) => {
                                            if (time) {
                                                setInterviewTime(timeString);
                                            } else {
                                                setInterviewTime(null);
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            {/* <Col xs={24} md={8}>
                  <Form.Item
                    className="remarks"
                    label="Interview Feedback"
                    name="remarks"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input.TextArea
                      placeholder="Add remark"
                      style={{ width: "80%" }}
                      value={remark}
                      onChange={(e) => setRemark(e.target.value)}
                    />
                  </Form.Item>
                </Col> */}
                        </Row>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" style={{ width: "26.7%" }}>
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Card>

            <Modal
                title="Previous Scheduled Interview Details"
                visible={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
            >
                {
                    showPreviousData ?
                        <>
                            <div style={{ marginTop: "20px" }}>
                                {previousInterviewData.map((d: any, index: any) => {
                                    let interviewData = d.interview_range;
                                    let roundName = d.interviewRoundData;
                                    let remark = d.remark;
                                    return (
                                        <>
                                            <div style={{ fontSize: "16px", marginBottom: "10px" }}>{`Interview ${index + 1}`}</div>
                                            <table className="previously-scheduled-table" style={{ width: "100%" }}>
                                                <tr>
                                                    <th>Rounds</th>
                                                    <th>Interviewers Name</th>
                                                    <th>Interview Date</th>
                                                    <th>Remarks</th>
                                                </tr>
                                                {interviewData?.map((d: any) => {
                                                    let currentRoundName = roundName.filter(
                                                        (data: any) => data?._id === d?.roundName
                                                    );
                                                    let interviewRemark = remark.filter(
                                                        (data: any) => data.interviewId === d._id
                                                    );

                                                    let localDate: any = new Date(d.interviewDate);
                                                    let dd: any = localDate.getDate();
                                                    let mm: any = localDate.getMonth() + 1;
                                                    let yyyy: any = localDate.getFullYear();
                                                    if (dd < 10) dd = "0" + dd;
                                                    if (mm < 10) mm = "0" + mm;
                                                    let interviewDate = `${dd}/${mm}/${yyyy}`;

                                                    return (
                                                        <>
                                                            <tr>
                                                                <td>{currentRoundName[0]?.status}</td>
                                                                <td>{d?.interviewerName}</td>
                                                                <td>{interviewDate}</td>
                                                                {
                                                                    interviewRemark && interviewRemark?.length > 0 ?
                                                                        <>
                                                                            {
                                                                                interviewRemark.map((d: any, i: any) => {
                                                                                    return (
                                                                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                                                                            <td
                                                                                                colSpan={i + 1}
                                                                                                rowSpan={i + 1}
                                                                                                style={{ borderBottom: " 1px solid rgb(199 191 191 / 87%)" }}
                                                                                            >
                                                                                                {d.remark}
                                                                                            </td>
                                                                                        </div>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </>
                                                                        :
                                                                        <td style={{ borderBottom: " 1px solid rgb(199 191 191 / 87%)" }}>-</td>
                                                                }
                                                            </tr>
                                                        </>
                                                    );
                                                })}
                                            </table>
                                        </>
                                    )
                                })}
                            </div>
                        </>
                        :
                        <div style={{ marginTop: "20px" }}>No Previously Scheduled Interview Found</div>
                }
            </Modal>
        </>
    );
};

export default CandidateScheduleInterview;
