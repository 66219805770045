import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "./reduxHooks";
import { doLogout } from "@app/store/slices/authSlice";
const useCountdown = (targetTime: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const countDownTime = new Date(targetTime).getTime();
  const [countDown, setCountDown] = useState(
    countDownTime - new Date().getTime()
  );

  useEffect(() => {
    let savedTimer = localStorage.getItem("timer");
    let timer: any;
    let interval: any;
    if (savedTimer) {
      timer = parseInt(savedTimer);
    } else {
      timer = 3000000;
    }
    setCountDown(timer);

    interval = setInterval(() => {
      if (countDownTime - new Date().getTime() > 0) {
        setCountDown(countDownTime - new Date().getTime());
      } else {
        clearInterval(interval);
        dispatch(doLogout()).then(() => {
          navigate("/sign-in");
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (countDown) {
      localStorage.setItem("timer", countDown.toString());
    } else {
      const afterHalfAndHour = 30 * 60 * 1000;
      const currentTime = new Date().getTime();
      const tokenExpiryTime = currentTime + afterHalfAndHour;
      const timer = tokenExpiryTime - currentTime;
      localStorage.setItem("timer", timer.toString());
    }
  }, [countDown]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown: any) => {
  // calculate time left
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [minutes, seconds];
};

export { useCountdown };
