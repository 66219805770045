import { AxiosError } from "axios";
import { notificationController } from "@app/utills/toastNotification";
import { Buffer } from 'buffer';
import { createDecipheriv, scryptSync } from "crypto";

export function handleAxiosError(err: any): boolean {
  const error = err as AxiosError<any>;
  notificationController.error(error.response?.data?.message);
  return false;
}

export function handleWordFormat(data: any) {
  let initialValue = data.split(" ")
  let middleValue = initialValue.map((word: any) => {
    return (
      word.length > 0 ? word[0]?.toUpperCase() + word.substring(1) : ""
    )
  });
  let finialValue = middleValue.join(" ")
  return (
    finialValue ? finialValue : ""
  )
}

// const passphrase = Buffer.from("Synergy Technology Services Pvt. Ltd");
// // Recover the key from the passphrase memorized and salt stored
// const SECRET_KEY = process.env.REACT_APP_SECRET_KEY || "";
// const key = scryptSync(passphrase, SECRET_KEY, 256 / 8);
// const iv = Buffer.from("BOlQb5etEWcEXh+j0+Habw==", "base64");

// export const giveEncrypted = async (data: string) => {
//   try {
//     const cipher = createCipheriv("aes256", key, iv);
//     const encryptedMessage =
//       cipher.update(data, "utf8", "hex") + cipher.final("hex");
//     return encryptedMessage;
//   } catch (error) {
//     return false;
//   }
// };

// export const giveDecipher = async (encryptedMessage: string) => {
//   try {
//     const decipher = createDecipheriv("aes256", key, iv);
//     const decryptedMessage =
//       decipher.update(encryptedMessage, "hex", "utf-8") +
//       decipher.final("utf8");
//     return decryptedMessage;
//   } catch (error) {
//     return false;
//   }
// };
