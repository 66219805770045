import React from "react";

const NotFoundPage = () => {
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <h1 style={{ fontSize: "5rem", color: "#2d2d2d" }}>404</h1>
        <h1 style={{ color: "#2d2d2d" }}>Oops, page not found</h1>
        <p>Sorry, but the requested page is not found</p>
      </div>
    </>
  );
};

export default NotFoundPage;
