import React, { useState, useEffect } from "react";
import { Layout, Drawer, Affix, Button } from "antd";
import { useLocation, Outlet } from "react-router-dom";
import { SideBar } from "./SideBar";
import { Footer } from "../footer/Footer";
import { Header } from "../header/Header";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const { Sider, Header: AntHeader, Content } = Layout;
const MainLayout: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("right");
  const [sidenavColor, setSidenavColor] = useState("#1890ff");
  const [sidenavType, setSidenavType] = useState("transparent");
  const [fixed, setFixed] = useState(false);
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const openDrawer = () => setVisible(!visible);
  const handleSidenavType = (type: string) => setSidenavType(type);
  const handleSidenavColor = (color: string) => setSidenavColor(color);
  const handleFixedNavbar = (type: boolean) => setFixed(type);

  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");
  useEffect(() => {
    if (pathname === "rtl") {
      setPlacement("left");
    } else {
      setPlacement("right");
    }
  }, [pathname]);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 992) {
        setCollapsed(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Layout
      className={`layout-dashboard ${
        pathname === "profile" ? "layout-profile" : ""
      } ${pathname === "rtl" ? "layout-dashboard-rtl" : ""}`}
    >
      {/* <Drawer
        title={false}
        placement={placement === "right" ? "left" : "right"}
        closable={false}
        onClose={() => setVisible(false)}
        visible={visible}
        key={placement === "right" ? "left" : "right"}
        width={250}
        className={`drawer-sidebar ${
          pathname === "rtl" ? "drawer-sidebar-rtl" : ""
        } `}
      >
        <Layout
          className={`layout-dashboard ${
            pathname === "rtl" ? "layout-dashboard-rtl" : ""
          }`}
        >
          <Sider
            trigger={null}
            width={250}
            theme="light"
            className={`sider-primary ant-layout-sider-primary ${
              sidenavType === "#fff" ? "active-route" : ""
            }`}
            style={{ background: sidenavType }}
          >
            <SideBar color={sidenavColor} />
          </Sider>
        </Layout>
      </Drawer> */}
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        // onCollapse={(collapsed, type) => {
        //   console.log(collapsed, type);
        // }}
        collapsible
        collapsed={collapsed}
        // onCollapse={(value)=>setCollapsed(value)}
        trigger={null}
        width={250}
        theme="light"
        className={`sider-primary ant-layout-sider-primary ${
          sidenavType === "#fff" ? "active-route" : ""
        }`}
        style={{ background: sidenavType, zIndex: 1 }}
      >
        <SideBar color={sidenavColor} />
      </Sider>

      <Layout className="sider-layout-arrow-btn">
        {fixed ? (
          <Affix>
            <Button
              type="default"
              onClick={() => setCollapsed(!collapsed)}
              style={{
                marginBottom: 16,
                width: "50px",
                zIndex: 2,
                borderRadius: "0",
                position: "fixed",
                float: "right",
              }}
            >
              {collapsed ? <RightOutlined /> : <LeftOutlined />}
            </Button>
            <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
              <Header
                onPress={openDrawer}
                name={pathname}
                subName={pathname}
                handleSidenavColor={handleSidenavColor}
                handleSidenavType={handleSidenavType}
                handleFixedNavbar={handleFixedNavbar}
              />
            </AntHeader>
          </Affix>
        ) : (
          <>
            <Button
              type="default"
              onClick={() => setCollapsed(!collapsed)}
              style={{
                marginBottom: 16,
                width: "50px",
                zIndex: 2,
                borderRadius: "0",
                position: "fixed",
                float: "right",
              }}
            >
              {collapsed ? <RightOutlined /> : <LeftOutlined />}
            </Button>
            <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
              <Header
                onPress={openDrawer}
                name={pathname}
                subName={pathname}
                handleSidenavColor={handleSidenavColor}
                handleSidenavType={handleSidenavType}
                handleFixedNavbar={handleFixedNavbar}
              />
            </AntHeader>
          </>
        )}
        <Content style={{ zIndex: 0 }} className="content-ant">
          <div>
            <Outlet />
          </div>
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
};

export default MainLayout;
