import React, { useState, useEffect } from "react";
import {
  Space,
  Row,
  Col,
  Card,
  Table,
  Button,
  Modal,
  Form,
  Input,
  DatePicker,
  Upload,
  UploadFile,
  UploadProps,
  Typography,
  Select,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { PlusOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import CandidateService from "@app/services/CandidateService";
import CandidateScheduleInterview from "./CandidateScheduleInterview";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { handleWordFormat } from "@app/utills/helpers";
import { RcFile } from "antd/es/upload";
import { notificationController } from "@app/utills/toastNotification";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import SourcesService from "@app/services/SourcesServices";
import Tooltip from "@mui/material/Tooltip";
import InterviewService from "@app/services/InterviewService";
import { useSelector } from "react-redux";
import { RootState } from "@app/store/store";
import CryptoJS from "crypto-js";
const { Search } = Input;
const { Title } = Typography;

const {
  createCipheriv,
  randomBytes,
  createDecipheriv,
  scryptSync,
} = require("crypto");

interface ICandidate {
  _id: string;
  name: string;
  email: string;
  mobile: number;
  sourced_by: string;
  profile: string;
  currentCtc: string;
  expectedCtc: string;
  reasonForChange: string;
  resume: string;
  comment: string;
  source: string;
  ctcOffered: string;
  interviewStatus: string;
  status: string;
  applicationDate: string;
  notice_period_range: NoticePeriodRange;
  newInfo: boolean;
}

interface NoticePeriodRange {
  range: number[];
  unit: string;
}

const downloadResume = async (path: string, fileName: string) => {
  const fileUrl = `/downloadfile/${path}`;
  const filename = `${fileName}`;
  axios
    .get(fileUrl, {
      responseType: "blob",
    })
    .then((response) => {
      const downloadUrl = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      notificationController.warning("Resume Not Found");
    });
};

const Candidate: React.FC = () => {
  const handleDecrypt = (data: string) => {
    const secretKey: string = process.env.REACT_APP_SECRET_KEY || "";

    const decryptedBytes = CryptoJS.AES.decrypt(data, secretKey);

    const decrypted = CryptoJS.AES.decrypt(
      decryptedBytes.toString(CryptoJS.enc.Utf8),
      secretKey
    );
    if (decrypted) {
      return decrypted.toString(CryptoJS.enc.Utf8);
    }
  };

  const permissions = useSelector(
    (state: RootState) => state.permissions.permissions
  );

  const IV = useSelector((state: RootState) => state.iv.iv);

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const addCandidate = () => {
    navigate("/add-candidate");
  };
  const [trigger, setTrigger] = useState<React.Key | null>(null);
  const [candidate, setCandidate] = useState<ICandidate[]>([]);
  let [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 10,
  });
  const [saveID, setSaveID] = useState<any>();
  const [viewCandidate, setViewCandidate] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [scheduleInterview, setScheduleInterview] = useState<boolean>(false);
  const [interviewData, setInterviewData] = useState<any>();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [searchName, setSearchName] = useState("");
  const [searchJob, setSearchJob] = useState<any>();
  const [sourceBy, setSourceBy] = useState<any>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [downloadStartDate, setDownloadStartDate] = useState<any>(null);
  const [downloadEndDate, setDownloadEndDate] = useState<any>(null);
  const [openDownloadModal, setOpenDownloadModal] = useState<boolean>(false);
  const [openFileDialogOnClick, setOpenFileDialogOnClick] = useState(true);
  const [searchValues, setSearchValues] = useState({
    email: "",
    mobile: 0,
  });
  const [email, setEmail] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploadExcel, setUploadExcel] = useState<any>(undefined);
  const [upload, setUpload] = useState(true);
  const [sources, setSources] = useState<any>(null);
  const [jobs, setJobs] = useState<any>(null);

  useEffect(() => {
    try {
      if (uploadExcel) {
        CandidateService.uploadCandidateExcel(uploadExcel)
          .then(() => {
            getAllCandidates(
              pagination.current,
              startDate,
              endDate,
              searchName,
              searchValues,
              sourceBy,
              searchJob
            );
            notificationController.success("File Uploaded Successfully");
          })
          .catch((err: any) => {
            notificationController.warning("Only Excel File Is Allowed");
          });
      }
      setTimeout(() => {}, 1000);
    } catch (err: any) {
      notificationController.error(err.response?.data?.message);
    }
  }, [uploadExcel]);

  const props: UploadProps = {
    onRemove: (file) => {
      setFileList((prevFileList) => {
        const index = prevFileList.indexOf(file);
        const newFileList = [...prevFileList];
        newFileList.splice(index, 1);
        return newFileList;
      });
      setUpload(true);
      return false;
    },
    beforeUpload: (file) => {
      if (fileList.length === 0) {
        setFileList([file]);
      }
      return false;
    },
    fileList,
  };

  const fetchSources = async () => {
    try {
      const candidateSources = await SourcesService.getCandidateSources();
      const fetchSources = candidateSources.data.data;
      setSources(fetchSources);
    } catch (err: any) {
      notificationController.error(err.response?.data?.message);
      setSources([]);
    }
  };

  const fetchJob = async () => {
    try {
      const candidateJobs = await InterviewService.getJobs();
      const fetchJobs = candidateJobs.data.data;
      setJobs(fetchJobs);
    } catch (err: any) {
      notificationController.error(err.response?.data?.message);
      setJobs([]);
    }
  };

  const getAllCandidates = async (
    pageNo: number,
    startDate: any,
    endDate: any,
    search: string,
    searchValues: { email: string; mobile: number },
    sourceBy: string,
    searchJob: string
  ) => {
    try {
      const formattedStartDate: string | null = startDate
        ? startDate.toISOString()
        : null;
      const formattedEndDate: string | null = endDate
        ? endDate.toISOString()
        : null;
      const { data } = await CandidateService.getCandidateApplication(
        pageNo,
        formattedStartDate,
        formattedEndDate,
        search,
        searchValues.email,
        searchValues.mobile,
        sourceBy,
        searchJob
      );
      setCandidate(data.data);
      let temp = { ...pagination };
      temp.current = data.pageInfo.currentPage;
      temp.total = pagination.pageSize * data.pageInfo.totalPages;
      setPagination(temp);
      setTrigger(null);
    } catch (err: any) {
      notificationController.error(err?.response?.data?.message);
    }
  };

  const showModal = (id: string, data: any) => {
    setSaveID(id);
    setIsModalOpen(true);
    if (data?.newInfo) {
      CandidateService.viewNewCandidateData(id);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    getAllCandidates(
      pagination.current,
      startDate,
      endDate,
      searchName,
      searchValues,
      sourceBy,
      searchJob
    );
  };

  useEffect(() => {
    getAllCandidates(
      pagination.current,
      startDate,
      endDate,
      searchName,
      searchValues,
      sourceBy,
      searchJob
    );
  }, [startDate, endDate, searchName, searchValues, sourceBy, searchJob]);

  useEffect(() => {
    var initialValues = candidate.filter((e) => {
      if (e._id == saveID) {
        return true;
      }
    });
    setViewCandidate(initialValues[0]);
  }, [saveID]);

  useEffect(() => {
    fetchSources();
    fetchJob();
  }, []);

  const handleScheduleInterview = (data: any) => {
    setScheduleInterview(true);
    setInterviewData(data);
  };

  const handleCandidatePage = () => {
    setScheduleInterview(false);
  };

  const handleEmailPopup = (value: any) => {
    setSaveID(value);
    setOpenModal(true);
  };

  const columns: ColumnsType<ICandidate> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150,
      render: (value, data) => (
        <div
          style={{
            wordBreak: "break-all",
            fontWeight: data.newInfo ? 700 : "unset",
          }}
          className="break-column-words"
        >
          {handleWordFormat(value)}
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 150,
      render: (value, data) => (
        <div
          style={{
            wordBreak: "break-all",
            fontWeight: data.newInfo ? 700 : "unset",
          }}
          className="break-column-words"
        >
          {value.toLowerCase()}
        </div>
      ),
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      width: 100,
      render: (value, data) => (
        <div
          style={{ fontWeight: data.newInfo ? 700 : "unset" }}
          className="break-column-words"
        >
          {value}
        </div>
      ),
    },
    {
      title: "Applied Job",
      dataIndex: "appliedJob",
      key: "appliedJob",
      width: 100,
      render: (value, data) => (
        <div
          style={{ fontWeight: data.newInfo ? 700 : "unset" }}
          className="break-column-words"
        >
          {value[0]?.title ? value[0]?.title : "-"}
        </div>
      ),
    },
    {
      title: "Application Date",
      dataIndex: "applicationDate",
      key: "applicationDate",
      width: 100,
      render: (applicationDate, data) => {
        let localDate: any = new Date(applicationDate);
        let dd: any = localDate.getDate();
        let mm: any = localDate.getMonth() + 1;
        let yyyy: any = localDate.getFullYear();

        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;

        return (
          <div
            style={{ fontWeight: data.newInfo ? 700 : "unset" }}
            className="break-column-words"
          >
            {`${dd}/${mm}/${yyyy}`}
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "",
      width: 100,
      render: (_: any, record: any) => (
        <div style={{ display: "flex", flexDirection: "row", gap: "1.5rem" }}>
          <Tooltip title="View More Details">
            <div
              style={{
                cursor: "pointer",
                color: record.newInfo ? "#2d2d2d" : "gray",
              }}
              onClick={() => showModal(record?._id, record)}
            >
              <VisibilityOutlinedIcon />
            </div>
          </Tooltip>
          {record?.resume === "" ? (
            <Tooltip title="Candidate Resume Not Found">
              <div style={{ color: "#dedede", cursor: "not-allowed" }}>
                <CloudDownloadOutlinedIcon />
              </div>
            </Tooltip>
          ) : (
            <Tooltip title="Download Resume">
              <div
                style={{
                  cursor: "pointer",
                  color: record.newInfo ? "#2d2d2d" : "gray",
                }}
                onClick={() => downloadResume(record?.resume, record?.name)}
              >
                <CloudDownloadOutlinedIcon />
              </div>
            </Tooltip>
          )}
          <Tooltip title="Schedule Interview">
            <div
              style={{
                cursor: "pointer",
                color: record.newInfo ? "#2d2d2d" : "gray",
              }}
              onClick={() => handleScheduleInterview(record)}
            >
              <ScheduleOutlinedIcon />
            </div>
          </Tooltip>
          <Tooltip title="Mail Candidate Details">
            <div
              style={{
                cursor: "pointer",
                color: record.newInfo ? "#2d2d2d" : "gray",
              }}
              onClick={() => handleEmailPopup(record?._id)}
            >
              <EmailOutlinedIcon />
            </div>
          </Tooltip>
        </div>
      ),
    },
  ];

  const pageChangeHandler = (pagination: any) => {
    const { current } = pagination;
    getAllCandidates(
      current,
      startDate,
      endDate,
      searchName,
      searchValues,
      sourceBy,
      searchJob
    );
  };

  const handleFileUpload = async () => {
    // const formData = new FormData();
    // fileList.forEach((file) => {
    //   formData.append("file", file as RcFile);
    // });
    // setUpload(false);
    // setUploadExcel(formData);
    if (fileList.length == 1 && fileList[0].status != "removed") {
      setUpload(false);
      const formData = new FormData();

      fileList.forEach((file) => {
        formData.append("file", file as RcFile);
      });
      // setUpload(false);
      setUploadExcel(formData);
    } else {
      setUpload(true);
    }
  };

  const handleDownloadStartDate = (e: any) => {
    let myDate = new Date(e);
    myDate.setHours(0);
    myDate.setMinutes(0);
    myDate.setSeconds(0);
    setDownloadStartDate(myDate.toISOString());
  };
  const handleDownloadEndDate = (e: any) => {
    let myDate = new Date(e);
    myDate.setHours(23);
    myDate.setMinutes(59);
    myDate.setSeconds(59);
    setDownloadEndDate(myDate.toISOString());
  };

  const onFilter = (name: any, e: any) => {
    if (name === "name") {
      setSearchName(e.target.value);
    }
    if (name === "searchJob") {
      setSearchJob(Array.isArray(e) ? e : [e]);
    }
    if (name === "sourceBy") {
      setSourceBy(Array.isArray(e) ? e : [e]);
    }
    if (name === "emailormobile") {
      setSearchValues({
        ...searchValues,
        email: e.includes("@") ? e : "",
        mobile: !e.includes("@") ? parseInt(e) : searchValues.mobile,
      });
    }
    if (name === "startDate") {
      setStartDate(e ? e.toDate() : null);
    }
    if (name === "endDate") {
      setEndDate(e ? e.toDate() : null);
    }
    let temp = { ...pagination };
    temp.current = 1;
    setPagination(temp);
    getAllCandidates(
      pagination.current,
      startDate,
      endDate,
      searchName,
      searchValues,
      sourceBy,
      searchJob
    );
  };

  const checkSpecialChar = (e: any) => {
    if (!/[a-zA-Z]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const onFinish = (values: any) => {
    let data: any = {
      email: values.email,
    };
    CandidateService.getCandidateDetailsByEmail(saveID, data)
      .then(() => {
        notificationController.success("Email Sent Successfully");
        setTimeout(() => {
          setOpenModal(false);
          form.resetFields();
        }, 300);
      })
      .catch(() => {
        notificationController.warning("Email Not Sent");
      });
  };

  const onDownloadFinish = () => {
    const fileName: any = "candidate_excel";
    const excelUrl = `/candidate/generate_excel/candidate?startDate=${downloadStartDate}&endDate=${downloadEndDate}`;
    axios
      .get(excelUrl, {
        responseType: "blob",
      })
      .then((response) => {
        const downloadUrl = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(() => {
        notificationController.warning("Something went wrong");
      });
  };

  const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

  return (
    <>
      {!scheduleInterview ? (
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="Candidate List"
                extra={
                  <Row gutter={16} align="middle">
                    <Col style={{ paddingLeft: "16px" }} xs={24} md={8}>
                      <Button onClick={() => setOpenDownloadModal(true)}>
                        Download Excel
                      </Button>
                    </Col>
                    <Col xs={24} md={8}>
                      <Form.Item
                        className="resume"
                        name="resume"
                        rules={[
                          {
                            required: true,
                            message: "Resume File Required",
                          },
                        ]}
                      >
                        <Upload
                          {...props}
                          accept=".xlsx"
                          fileList={fileList}
                          onChange={handleFileUpload}
                          onRemove={() => {
                            setFileList([]);
                          }}
                          openFileDialogOnClick={openFileDialogOnClick}
                        >
                          <Button
                            icon={<UploadOutlined />}
                            style={{ marginTop: "23px" }}
                            disabled={!upload}
                          >
                            Upload Excel
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                      <Button type="primary" onClick={() => addCandidate()}>
                        <PlusOutlined style={{ fontSize: "14px" }} />
                        Add Candidate
                      </Button>
                    </Col>
                  </Row>
                }
              >
                <Row gutter={[16, 0]}>
                  <Col xs={24} md={8}>
                    <Form.Item className="name" name="name" rules={[]}>
                      <Input
                        placeholder="Search by name"
                        onChange={(e) => onFilter("name", e)}
                        onKeyDown={(e) => checkSpecialChar(e)}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item className="searchJob" name="searchJob">
                      <Select
                        showSearch
                        defaultValue={[]}
                        size="large"
                        allowClear
                        onChange={(e) => onFilter("searchJob", e)}
                        filterOption={(inputValue, option) =>
                          option?.children
                            ? String(option.children)
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                            : true
                        }
                        placeholder="Search by Job"
                      >
                        {jobs && Array.isArray(jobs) ? (
                          jobs.map((item) => {
                            return (
                              <option key={item._id} value={item._id}>
                                {`${item.title}`}
                              </option>
                            );
                          })
                        ) : (
                          <option>Choose...</option>
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item className="sourceBy" name="sourceBy">
                      <Select
                        showSearch
                        defaultValue={[]}
                        size="large"
                        allowClear
                        onChange={(e) => onFilter("sourceBy", e)}
                        filterOption={(inputValue, option) =>
                          option?.children
                            ? String(option.children)
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                            : true
                        }
                        placeholder="Search by Source"
                      >
                        {sources && Array.isArray(sources) ? (
                          sources.map((item) => {
                            return (
                              <option key={item._id} value={item._id}>
                                {`${item.sourceName}`}
                              </option>
                            );
                          })
                        ) : (
                          <option>Choose...</option>
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} md={8}>
                    <Form.Item
                      className="emailormobile"
                      name="emailormobile"
                      rules={[]}
                    >
                      <Search
                        placeholder="Search by email or mobile"
                        style={{ width: "96.5%" }}
                        value={
                          searchValues.email || searchValues.mobile.toString()
                        }
                        onSearch={(e) => onFilter("emailormobile", e)}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      className="startDate"
                      name="startDate"
                      rules={[]}
                    >
                      <DatePicker
                        placeholder="Application From"
                        style={{
                          marginLeft: "6px",
                          width: "96%",
                          height: "42px",
                        }}
                        onChange={(e) => onFilter("startDate", e)}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item className="endDate" name="endDate" rules={[]}>
                      <DatePicker
                        placeholder="Application To"
                        style={{
                          marginLeft: "13px",
                          width: "96%",
                          height: "42px",
                        }}
                        onChange={(e) => onFilter("endDate", e)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={candidate}
                    pagination={{ ...pagination, showSizeChanger: false }}
                    onChange={pageChangeHandler}
                    className="ant-border-space"
                    key={trigger}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <CandidateScheduleInterview
          candidateId={interviewData?._id}
          candidateName={interviewData?.name}
          emailId={interviewData?.email}
          jobId={interviewData?.appliedJob[0]?._id}
          jobName={interviewData?.appliedJob[0]?.title}
          backUrl={handleCandidatePage}
        />
      )}

      <Modal
        title="Candidate Details"
        centered
        visible={isModalOpen}
        onCancel={handleCancel}
        width={600}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        style={{ zIndex: 1 }}
      >
        <table className="candidate-know-more" style={{ width: "100%" }}>
          <tr>
            <th>Name:</th>
            <td>{viewCandidate?.name ? viewCandidate?.name : "-"}</td>
          </tr>
          <tr>
            <th>Email:</th>
            <td>{viewCandidate?.email ? viewCandidate?.email : "-"}</td>
          </tr>
          <tr>
            <th>Mobile:</th>
            <td>{viewCandidate?.mobile ? viewCandidate?.mobile : "-"}</td>
          </tr>
          <tr>
            <th>Applied Job:</th>
            <td>
              {viewCandidate?.appliedJob && viewCandidate?.appliedJob[0]?.title
                ? viewCandidate?.appliedJob[0]?.title
                : "-"}
            </td>
          </tr>
          <tr>
            <th>Location:</th>
            <td>{viewCandidate?.location ? viewCandidate?.location : "-"}</td>
          </tr>
          <tr>
            <th>Profile:</th>
            <td>{viewCandidate?.profile ? viewCandidate?.profile : "-"}</td>
          </tr>
          <tr>
            <th>Expereince:</th>
            <td>
              {viewCandidate?.experience ? viewCandidate?.experience : "-"}
            </td>
          </tr>
          <tr>
            <th>Notice Period:</th>
            <td>
              {viewCandidate?.noticePeriod ? viewCandidate?.noticePeriod : "-"}
            </td>
          </tr>
          <tr>
            <th>Current CTC:</th>
            <td>
              {viewCandidate?.currentCtc ? `${viewCandidate?.currentCtc} LPA` : "-"}
            </td>
          </tr>
          <tr>
            <th>Expected CTC:</th>
            <td>
              {viewCandidate?.expectedCtc ? `${viewCandidate?.expectedCtc} LPA` : "-"}
            </td>
          </tr>
          {permissions.includes("show-candidate-ctc") && (
            <>
              <tr>
                <th>Offered CTC:</th>
                <td>
                  {viewCandidate?.ctcOffered
                    ? handleDecrypt(viewCandidate?.ctcOffered)+" LPA"
                    : "-"}
                </td>
              </tr>
            </>
          )}
          <tr>
            <th>Source By:</th>
            <td>
              {viewCandidate?.sourcedBy &&
              viewCandidate?.sourceData[0]?.sourceName
                ? viewCandidate?.sourceData[0]?.sourceName
                : "-"}
            </td>
          </tr>
          <tr>
            <th>Reason For Change:</th>
            <td>
              {viewCandidate?.reasonForChange
                ? viewCandidate?.reasonForChange
                : "-"}
            </td>
          </tr>
          <tr>
            <th>Comment:</th>
            <td>{viewCandidate?.comment ? viewCandidate?.comment : "-"}</td>
          </tr>
        </table>
      </Modal>

      <Modal
        visible={openModal}
        onCancel={() => setOpenModal(false)}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div>
          <Title level={5}>Mail To:</Title>
        </div>
        <Form
          onFinish={onFinish}
          layout="vertical"
          form={form}
          className="row-col"
        >
          <Form.Item
            className="email"
            label="Email Id:"
            name="email"
            rules={[
              { required: true, message: "Email Required" },
              {
                pattern: emailPattern,
                message: "Invalid Email!",
              },
            ]}
          >
            <Input
              placeholder="Enter Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        visible={openDownloadModal}
        centered
        onCancel={() => setOpenDownloadModal(false)}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div style={{ marginBottom: "15px" }}>
          <Title level={5}>Download Candidate Details: </Title>
        </div>
        <div>
          <Form
            onFinish={onDownloadFinish}
            layout="vertical"
            className="row-col"
          >
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
              }}
            >
              <Col>
                <Form.Item
                  className="downloadStartDate"
                  name="downloadStartDate"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Date",
                    },
                  ]}
                >
                  <DatePicker
                    placeholder="From"
                    onChange={(e) => handleDownloadStartDate(e)}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  className="downloadEndDate"
                  name="downloadEndDate"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Date",
                    },
                  ]}
                >
                  <DatePicker
                    placeholder="To"
                    style={{}}
                    onChange={(e) => handleDownloadEndDate(e)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "27%" }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default Candidate;
