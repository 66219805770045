import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@app/store/store";
export interface SidebarNavigationItem {
  title: string;
  key: string;
  roles?: string[];
  premissions?: string[];
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}
export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: "Dashboard",
    key: "/",
    roles: ["trainer", "trainee"],
    premissions: ["show-dashboard"],
    url: "/",
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        key={0}
      >
        <path
          d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
          fill={"#1890FF"}
        ></path>
        <path
          d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
          fill={"#1890FF"}
        ></path>
        <path
          d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
          fill={"#1890FF"}
        ></path>
      </svg>
    ),
  },
  {
    title: "User",
    key: "/user",
    roles: ["trainer", "trainee"],
    premissions: ["remove-hr", "modify-hr", "show-hr", "add-hr"],
    url: "/user",
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        key={0}
      >
        <path
          d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z"
          fill={"#1890FF"}
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
          fill={"#1890FF"}
        ></path>
      </svg>
    ),
  },
  {
    title: "Jobs",
    key: "/jobs",
    roles: ["trainer", "trainee"],
    premissions: ["add-job", "modify-job", "close-job"],
    url: "/jobs",
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        key={0}
      >
        <path
          d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z"
          fill={"#1890FF"}
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
          fill={"#1890FF"}
        ></path>
      </svg>
    ),
  },
  {
    title: "Candidate",
    key: "/candidates",
    roles: ["trainer", "trainee"],
    premissions: ["modify-candidate", "show-candidates"],
    url: "/candidates",
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        key={0}
      >
        <path
          d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z"
          fill={"#1890FF"}
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
          fill={"#1890FF"}
        ></path>
      </svg>
    ),
  },
  // {
  //   title: "Candidate",
  //   key: "/candidates",
  //   roles: ["trainer", "trainee"],
  //   permissions: ["modify-candidate", "show-candidates"],
  //   url: "/candidates",
  //   icon: (
  //     <svg
  //       width="20"
  //       height="20"
  //       viewBox="0 0 20 20"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //       key={0}
  //     >
  //       <path
  //         d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z"
  //         fill={"#1890FF"}
  //       ></path>
  //       <path
  //         fillRule="evenodd"
  //         clipRule="evenodd"
  //         d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
  //         fill={"#1890FF"}
  //       ></path>
  //     </svg>
  //   ),
  // },
  {
    title: "Roles",
    key: "/roles",
    roles: ["trainer", "trainee"],
    premissions: ["show-role", "modify-role", "remove-role", "add-role"],
    url: "/roles",
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        key={0}
      >
        <path
          d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z"
          fill={"#1890FF"}
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
          fill={"#1890FF"}
        ></path>
      </svg>
    ),
  },
  {
    title: "Interview",
    key: "/interview",
    roles: ["trainer", "trainee"],
    premissions: [
      "update-interview",
      "show-interviews",
      "create-interview",
      "dalete-interview",
    ],
    url: "/interview",
    icon: (
      // <svg
      //   width="20"
      //   height="20"
      //   viewBox="0 0 20 20"
      //   fill="none"
      //   xmlns="http://www.w3.org/2000/svg"
      //   key={0}
      // >
      //   <path
      //     d="M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z"
      //     fill={"#1890FF"}
      //   ></path>
      //   <path
      //     fillRule="evenodd"
      //     clipRule="evenodd"
      //     d="M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z"
      //     fill={"#1890FF"}
      //   ></path>
      // </svg>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        key={0}
      >
        <path
          d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z"
          fill={"#1890FF"}
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
          fill={"#1890FF"}
        ></path>
      </svg>
    ),
  },
  // {
  //   title: "Experience",
  //   key: "/experience",
  //   roles: ["trainer", "trainee"],
  //   premissions: ["add-experience"],
  //   url: "/experience",
  //   icon: (
  //     <svg
  //       width="20"
  //       height="20"
  //       viewBox="0 0 20 20"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //       key={0}
  //     >
  //       <path
  //         d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z"
  //         fill={"#1890FF"}
  //       ></path>
  //       <path
  //         fillRule="evenodd"
  //         clipRule="evenodd"
  //         d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
  //         fill={"#1890FF"}
  //       ></path>
  //     </svg>
  //   ),
  // },
  {
    title: "Sources",
    key: "/sources",
    roles: ["trainer", "trainee"],
    premissions: ["show-all-source"],
    url: "/sources",
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        key={0}
      >
        <path
          d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z"
          fill={"#1890FF"}
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
          fill={"#1890FF"}
        ></path>
      </svg>
    ),
  },
];
const Sidebar = () => {
  const permissions = useSelector(
    (state: RootState) => state.permissions.permissions
  );
  const visibleSidebarItems = sidebarNavigation.filter(
    (item) => !item.roles || item.roles.includes("add-role")
  );
  return (
    <div className="sidebar">
      {visibleSidebarItems.map((item) => (
        <span>{item.title}</span>
      ))}
    </div>
  );
};
export default Sidebar;
