import axios from "axios";

const SourcesService = {

    getAllSources: (pageNo: number, search?: string) => {
        let url: string = `/source/show?pageNo=${pageNo}`
        if (search) {
            url += `&sourceBy=${search}`
        }
        return axios.get(url)
    },

    getSources: (sourceId: string) => {
        return axios.get(`/source/show/${sourceId}`);
      },

    getCandidateSources: () => {
        return axios.get("/source/candidate");
    },

    getSourcesType: () => {
        return axios.get("/source/type");
    },

    addSources: (data: any) => {
        return axios.post("/source/create", data);
    },

    updateSources: (sourceId: string, users: any) => {
        return axios.put(`/source/${sourceId}`, users);
    },

    deleteSources: (sourceId: string) => {
        return axios.delete(`/source/${sourceId}`);
    },
};

export default SourcesService;
