import AppRouter from "./router/AppRouter";
import "./App.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import axiosSetup from "./utills/axiosSetup";

function App() {
  axiosSetup();
  return (
    <>
      <AppRouter />
    </>
  );
}

export default App;
