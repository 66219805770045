import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer, FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { authSlice } from "./slices/authSlice";
import { api } from "./slices/apiSlice";
import { permissionsSlice } from "./slices/permissionSlice";
import { ivSlice } from "./slices/ivSlice";

const persistConfig = {
  key: "auth",
  storage,
};
const persistPermissionConfig = {
  key: "permission",
  storage,
};
const persistIvConfig = {
  key: "iv",
  storage,
};

const persistedAuthReducer = persistReducer(persistConfig, authSlice.reducer);
const persistedPermissionReducer = persistReducer(persistPermissionConfig, permissionsSlice.reducer);
const persistedIvReducer = persistReducer(persistIvConfig, ivSlice.reducer);

export const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    permissions: persistedPermissionReducer,
    iv: persistedIvReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      }
    }).concat([api.middleware]),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
