import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ILoginResponse, Status } from "../../services/AuthService";
import authService, {
  ILoginForm,
  ILoginFormWithPassword,
} from "../../services/AuthService";
import { AxiosError } from "axios";
import {
  deleteToken,
  persistToken,
  deletePersistUser,
} from "../../services/LocalStorageService";
import { AES, enc } from "crypto-js";
import { setPermissions, clearPermissions } from "./permissionSlice";
export interface AuthSlice {
  token: string;
  status: Status;
  error: string | null;
}

const initialState: AuthSlice = {
  token: "",
  status: Status.IDLE,
  error: "",
};

export const doLogin = createAsyncThunk<
  ILoginResponse,
  ILoginForm,
  {
    rejectValue: AxiosError;
  }
>(
  "/sign-in/google",
  async (
    loginPayload: ILoginForm,
    { rejectWithValue, dispatch }
  ): Promise<any> => {
    try {
      const res = await authService.login(loginPayload);
      const data: ILoginResponse = res.data;
      persistToken(data.data.token);
      const afterHalfAndHour = 30 * 60 * 1000;
      const currentTime = new Date().getTime();
      const tokenExpiryTime = currentTime + afterHalfAndHour;
      const timer = tokenExpiryTime - currentTime;
      localStorage.setItem("maximumTimer", tokenExpiryTime.toString());
      localStorage.setItem("timer", timer.toString());
      const roles = AES.decrypt(data.data.allowedHeaders, data.data.token);
      const userPermissions = roles.toString(enc.Utf8).split(",");
      dispatch(setPermissions(userPermissions));
      return data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  }
);

export const extendAccessToken = createAsyncThunk<
  ILoginResponse,
  {
    rejectValue: AxiosError;
  }
>(
  "/sign-in/extendAccessToken",
  async (loginPayload: any, { rejectWithValue, dispatch }): Promise<any> => {
    try {
      const res = await authService.extendAccessToken();
      const data: ILoginResponse = res.data;
      persistToken(data.data.token);
      const afterHalfAndHour = 30 * 60 * 1000;
      const currentTime = new Date().getTime();
      const tokenExpiryTime = currentTime + afterHalfAndHour;
      const timer = tokenExpiryTime - currentTime;
      localStorage.setItem("maximumTimer", tokenExpiryTime.toString());
      localStorage.setItem("timer", timer.toString());
      const roles = AES.decrypt(data.data.allowedHeaders, data.data.token);
      const userPermissions = roles.toString(enc.Utf8).split(",");
      dispatch(setPermissions(userPermissions));
      return data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  }
);

export const doLoginWithPassword = createAsyncThunk<
  ILoginResponse,
  ILoginFormWithPassword,
  {
    rejectValue: AxiosError;
  }
>(
  "/sign-in",
  async (
    loginPayload: ILoginFormWithPassword,
    { rejectWithValue, dispatch }
  ): Promise<any> => {
    try {
      const res = await authService.loginWithPassword(loginPayload);
      const data: ILoginResponse = res.data;
      persistToken(data.data.token);
      const afterHalfAndHour = 30 * 60 * 1000;
      const currentTime = new Date().getTime();
      const tokenExpiryTime = currentTime + afterHalfAndHour;
      const timer = tokenExpiryTime - currentTime;
      localStorage.setItem("maximumTimer", tokenExpiryTime.toString());
      localStorage.setItem("timer", timer.toString());
      const roles = AES.decrypt(data.data.allowedHeaders, data.data.token);
      const userPermissions = roles.toString(enc.Utf8).split(",");
      dispatch(setPermissions(userPermissions));
      return data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  }
);

export const doLogout = createAsyncThunk(
  "/sign-out",
  (payload, { dispatch }) => {
    deleteToken();
    deletePersistUser();
    dispatch(clearPermissions());
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        doLogin.fulfilled,
        (state, action: PayloadAction<ILoginResponse>) => {
          state.token = action.payload.data.token;
          state.status = Status.IDLE;
        }
      )
      .addCase(doLogin.pending, (state) => {
        state.status = Status.LOADING;
      })
      .addCase(doLogin.rejected, (state) => {
        state.status = Status.ERROR;
      });
    builder
      .addCase(
        extendAccessToken.fulfilled,
        (state, action: PayloadAction<ILoginResponse>) => {
          state.token = action.payload.data.token;
          state.status = Status.IDLE;
        }
      )
      .addCase(extendAccessToken.pending, (state) => {
        state.status = Status.LOADING;
      })
      .addCase(extendAccessToken.rejected, (state) => {
        state.status = Status.ERROR;
      });
    builder
      .addCase(
        doLoginWithPassword.fulfilled,
        (state, action: PayloadAction<ILoginResponse>) => {
          state.token = action.payload.data.token;
          state.status = Status.IDLE;
        }
      )
      .addCase(doLoginWithPassword.pending, (state) => {
        state.status = Status.LOADING;
      })
      .addCase(doLoginWithPassword.rejected, (state) => {
        state.status = Status.ERROR;
      });
    builder
      .addCase(doLogout.fulfilled, (state) => {
        state.token = "";
        state.status = Status.IDLE;
      })
      .addCase(doLogout.pending, (state) => {
        state.status = Status.LOADING;
      })
      .addCase(doLogout.rejected, (state) => {
        state.status = Status.ERROR;
      });
  },
});
