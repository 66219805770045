import React, { useState } from "react";
import { Menu } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import logo from "@app/assets/images/logo-white.svg";
import { sidebarNavigation } from "../common/sidebarNavigation";
import { useSelector } from "react-redux";
import { RootState } from "@app/store/store";
import "../../assets/styles/responsive.css";

export const SideBar: React.FC<{ color: string }> = ({ color }) => {
  // const { pathname } = useLocation();
  let { pathname } = useLocation();
  const page = pathname.startsWith("/") ? pathname.substring(1) : pathname;
  // const page = pathname.replace("/", "");
  const userPermissions: any = useSelector(
    (state: RootState) => state.permissions.permissions
  );
  return (
    <>
      <div className="brand">
        <img src={logo} alt="" />
      </div>
      <hr />
      <Menu className="menu" theme="light" mode="inline">
        {sidebarNavigation.map((nav) => {
          const hasPermission =
            nav.premissions &&
            nav.premissions.some((permission) =>
              userPermissions.includes(permission)
            );
          if (hasPermission) {
            return (
              <Menu.Item key={nav.key} title="">
                <NavLink to={nav.url || ""}>
                  <span
                    className="icon"
                    style={{
                      background: page === nav.url ? color : "",
                    }}
                  >
                    {nav.icon}
                  </span>
                  <span className="label">{nav.title}</span>
                </NavLink>
              </Menu.Item>
            );
          }
          return null;
        })}
      </Menu>
    </>
  );
};
