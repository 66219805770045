import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { withLoading } from "@app/hocs/withLoading.hoc";
import MainLayout from "@app/components/layouts/MainLayout";
import LoginPage from "@app/components/auth/SignIn";
import CandidatePage from "../components/candidate/CandidateList";
import RequireAuth from "./RequireAuth";
import NotFoundPage from "@app/components/common/NotFoundPage";
import ShowResume from "@app/components/common/ShowResume";

const DashboardPage = React.lazy(
  () => import("@app/components/dashboard/Dashboard")
);
const NotFount404Page = React.lazy(() => import("@app/errorPage"));

const CandidateListingPage = React.lazy(
  () => import("@app/components/candidate/CandidateList")
);
const AddCandidatePage = React.lazy(
  () => import("@app/components/candidate/AddCandidate")
);

const JobListingPage = React.lazy(
  () => import("@app/components/jobs/JobListing")
);
const AddJobPage = React.lazy(() => import("@app/components/jobs/AddJob"));
const EditJobPage = React.lazy(() => import("@app/components/jobs/EditJob"));

const ShowRolePage = React.lazy(() => import("@app/components/roles/ShowRole"));
const AddRolePage = React.lazy(() => import("@app/components/roles/AddRole"));
const UpdateRolePage = React.lazy(
  () => import("@app/components/roles/UpdateRole")
);

const ViewInterviewPage = React.lazy(
  () => import("@app/components/interview/ViewInterview")
);
const ScheduleInterviewPage = React.lazy(
  () => import("@app/components/interview/ScheduleInterview")
);
const UpdateInterviewPage = React.lazy(
  () => import("@app/components/interview/UpdateInterview")
);

// const ShowExperiencePage = React.lazy(
//   () => import("@app/components/experience/ShowExperience")
// );
// const AddExperiencePage = React.lazy(
//   () => import("@app/components/experience/AddExperience")
// );

const ViewSourcesPage = React.lazy(
  () => import("@app/components/sources/ViewSources")
);
const AddSourcesPage = React.lazy(
  () => import("@app/components/sources/AddSources")
);
const UpdateSourcesPage = React.lazy(
  () => import("@app/components/sources/UpdateSources")
);

const PermissionPage = React.lazy(
  () => import("@app/components/permission/ShowPermission")
);

const ViewUserPage = React.lazy(() => import("@app/components/user/ViewUser"));
const AddUserPage = React.lazy(() => import("@app/components/user/AddUser"));
const UpdateUserPage = React.lazy(
  () => import("@app/components/user/UpdateUser")
);

const Dashboard = withLoading(DashboardPage);
const JobList = withLoading(JobListingPage);
const NotFound = withLoading(NotFount404Page);

const CandidateList = withLoading(CandidateListingPage);
const AddCandidate = withLoading(AddCandidatePage);

const AddJob = withLoading(AddJobPage);
const EditJob = withLoading(EditJobPage);

const ShowRole = withLoading(ShowRolePage);
const AddRole = withLoading(AddRolePage);
const UpdateRole = withLoading(UpdateRolePage);

const ViewInterview = withLoading(ViewInterviewPage);
const ScheduleInterview = withLoading(ScheduleInterviewPage);
const UpdateInterview = withLoading(UpdateInterviewPage);

// const ShowExperience = withLoading(ShowExperiencePage);
// const AddExperience = withLoading(AddExperiencePage);

const ViewSources = withLoading(ViewSourcesPage);
const AddSources = withLoading(AddSourcesPage);
const UpdateSources = withLoading(UpdateSourcesPage);

const ViewPermissions = withLoading(PermissionPage);

const ViewUser = withLoading(ViewUserPage);
const AddUser = withLoading(AddUserPage);
const UpdateUser = withLoading(UpdateUserPage);

export const JOB_SERVICE_DASHBOARD_PATH = "/";

const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={JOB_SERVICE_DASHBOARD_PATH} element={protectedLayout}>
          <Route index element={<Dashboard />} />
          <Route path="/jobs" element={<JobList />} />
          <Route path="/show-candidates" element={<CandidateList />} />
          <Route path="/add-job" element={<AddJob />} />
          <Route path="/edit-job/:jobId" element={<EditJob />} />
          <Route path="/candidates" element={<CandidatePage />} />
          <Route path="/add-candidate" element={<AddCandidate />} />
          <Route path="/roles" element={<ShowRole />} />
          <Route path="/add-role" element={<AddRole />} />
          <Route path="/edit-role/:roleId" element={<UpdateRole />} />
          <Route path="/interview" element={<ViewInterview />} />
          <Route path="/schedule-interview" element={<ScheduleInterview />} />
          <Route
            path="/edit-interview/:interviewId"
            element={<UpdateInterview />}
          />
          {/* <Route path="/experience" element={<ShowExperience />} />
          <Route path="/add-experience" element={<AddExperience />} /> */}
          <Route path="/sources" element={<ViewSources />} />
          <Route path="/add-sources" element={<AddSources />} />
          <Route path="/edit-sources/:sourceId" element={<UpdateSources />} />
          <Route path="/permissions" element={<ViewPermissions />} />
          <Route path="/user" element={<ViewUser />} />
          <Route path="/add-user" element={<AddUser />} />
          <Route path="/edit-user/:userId" element={<UpdateUser />} />
        </Route>
        <Route path="/sign-in" element={<LoginPage />} />
        <Route path="/wkqvxNwVql/:slug" element={<ShowResume />} />
        <Route path="*" element={<NotFound children={<NotFoundPage />} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
