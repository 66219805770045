import axios from "axios";

const CandidateService = {
  getCandidateApplication: (pageNo: number, startDate: any, endDate: any, search: string, email: string, mobile: number, sourceBy: string, searchJob: string) => {
    let url: string = `/candidate/application/?pageNo=${pageNo}`
    if (startDate) {
      url += `&startDate=${startDate}`
    }
    if (endDate) {
      url += `&endDate=${endDate}`
    }
    if (search) {
      url += `&search=${search}`
    }
    if (email) {
      url += `&email=${email}`;
    }
    if (mobile) {
      url += `&mobile=${mobile}`;
    }
    if (sourceBy) {
      url += `&sourcedBy=${sourceBy}`;
    }
    if (searchJob) {
      url += `&job=${searchJob}`;
    }
    return axios.get(url);
  },

  uploadCandidateExcel: (formData: any) => {
    return axios.post("/candidate/upload", formData);
  },

  getCandidateById: (candidateId: string) => {
    return axios.get(`/candidate/application/${candidateId}`)
  },

  getCandidateResume: (resumePath: string) => {
    return axios.get(`/downloadfile/${resumePath}`);
  },

  addCandidate: (formData: any) => {
    return axios.post("/candidate/application/", formData);
  },

  filterCandidate: (pageNo: number, startDate: string, endDate: string, search: string) => {
    return axios.get(`/candidate/filter?pageNo=${pageNo}&startDate=${startDate}&endDate=${endDate}&search=${search}`);
  },

  getCandidateDetailsByEmail: (id: any, email: any) => {
    let url: string = `/send/email/${id}`
    return axios.post(url, email);
  },

  viewNewCandidateData: (id: any) => {
    let url: string = `/mark/read/${id}`
    return axios.post(url);
  }

};

export default CandidateService;
