import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Layout, Menu, Button, Row, Col, Typography, Form, Input } from "antd";
import signinbg from "../../assets/images/signin-img-min.jpg";
import stsLogo from "../../assets/images/sts-logo.svg";
import { ErrorMessage, Formik } from "formik";
import { ILoginForm, ILoginFormWithPassword } from "@app/services/AuthService";
import { doLogin, doLoginWithPassword } from "@app/store/slices/authSlice";
import * as yup from "yup";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { readToken } from "@app/services/LocalStorageService";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useGoogleLogin } from "@react-oauth/google";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import "./GoogleLoginButton.css";
import { notificationController } from "@app/utills/toastNotification";
import { handleAxiosError } from "@app/utills/helpers";
const { Title } = Typography;
const { Header, Footer, Content } = Layout;


const LoginSchema = yup.object({
  usernameoremail: yup.string().trim().required("Email Required"),
  password: yup.string().trim().required("Password Required"),
});

const SignIn: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [usernameoremail, setEmail] = useState("");

  const [showLogin, setShowLogin] = useState(false);

  const [password, setPassword] = useState("");

  const handleLogin = async (data: ILoginForm) => {
    dispatch(doLogin(data))
      .unwrap()
      .then(() => {
        notificationController.success("Login Successfully");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      })
      .catch((err: any) => {
        handleAxiosError(err)
        // notificationController.error("Invalid Credentials !!");
      });
  };

  const handleLoginWithPassword = async (data: ILoginFormWithPassword) => {

    const token = readToken();
    if (token) {
      notificationController.warning("You are already logged in");
      return;
    }
    dispatch(doLoginWithPassword(data))
      .unwrap()
      .then(() => {
        notificationController.success("Login Successfully");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      })
      .catch((err: any) => {
        handleAxiosError(err)
        // notificationController.error('Please, Enter Correct Credentials');
      });
  };

  const onEmailChangeHnd = (e: any) => {
    const pattern = /@stspl\.com/;
    if (pattern.test(e.target.value)) {
      setShowLogin(true);
    } else {
      setShowLogin(false);
    }
    setEmail(e.target.value);
  };

  const onPasswordChangeHnd = (e: any) => {
    setPassword(e.target.value);
  };

  const onFinish = (values: { usernameoremail: any; password: any }) => {
    if (Object.keys(validateForm(values)).length === 0) {
      handleLoginWithPassword({
        usernameoremail: values.usernameoremail,
        password: values.password,
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    notificationController.error(errorInfo);
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse: { access_token: any }) => {
      handleLogin({ userverify: tokenResponse?.access_token });
    },
  });

  const validateForm = (values: any) => {
    const errors: { [key: string]: string } = {};

    if (!values.usernameoremail) {
      errors.usernameoremail = "UserName or Mail is required";
    }
    if (!values.password) {
      errors.password = "Password is required";
    }
    return errors;
  };
  useEffect(() => {
    const token = readToken();
    if (token) {
      navigate("/");
    }
  }, []);
  return (
    <>
      <Layout className="layout-default layout-signin">
        <Header>
          <div className="header-col header-brand">
            <img style={{ width: "80px" }} src={stsLogo} alt="" />
          </div>
          <div className="header-col header-nav">
            <Menu mode="horizontal" defaultSelectedKeys={["1"]}>
              <Menu.Item key="4">
                <Link to="/sign-in">
                </Link>
              </Menu.Item>
            </Menu>
          </div>
        </Header>
        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <pre style={{ textAlign: "center" }}>
                <Title className="mb-15">Sign In</Title>
                <Title className="font-regular text-muted" level={5}>
                  Please sign-in to your account
                </Title>
              </pre>
              <Formik
                initialValues={{
                  usernameoremail: usernameoremail,
                  password: password,
                }}
                validate={validateForm}
                validationSchema={LoginSchema}
                onSubmit={async (values) => {
                  await handleLoginWithPassword(values);
                }}
              >
                {({
                  errors,
                  touched,
                }) => (
                  <Form
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                    className="row-col"
                  >
                    <Form.Item
                      className="username"
                      label="Username or Email"
                      name="usernameoremail"
                      rules={[
                        {
                          required: true,
                          message: "Please input your username or email!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter your username or email"
                        onChange={onEmailChangeHnd}
                      />
                    </Form.Item>
                    <ErrorMessage
                      name="usernameoremail"
                      component="div"
                      className="error-message"
                    />
                    {!showLogin ? (
                      <>
                        <Form.Item
                          className="username"
                          label="Password"
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Please input your password!",
                            },
                            {
                              pattern: /^.{8,50}$/,
                              message: 'Password must be of atleast 8 characters',
                            },
                          ]}
                        >
                          <Input.Password
                            placeholder="············"
                            onChange={onPasswordChangeHnd}
                            iconRender={(visible: any) =>
                              visible ? (
                                <EyeTwoTone />
                              ) : (
                                <EyeInvisibleOutlined />
                              )
                            }
                          />
                        </Form.Item>
                        {errors.password && touched.password && (
                          <div>{errors.password}</div>
                        )}
                        <Form.Item>
                          <Button
                            htmlType="submit"
                            style={{
                              width: "100%",
                              backgroundColor: "#1F536C",
                              color: "white",
                              marginTop: "5px",
                            }}
                          >
                            SIGN IN
                          </Button>
                        </Form.Item>
                      </>
                    ) : null}
                  </Form>
                )}
              </Formik>
              {showLogin ? (
                <div>
                  <Button
                    id="google-sign-in-button"
                    className="google-sign-in-button"
                    style={{
                      width: "100%",
                      backgroundColor: "#1F536C",
                      color: "white",
                    }}
                    onClick={() => login()}
                  >
                    <FontAwesomeIcon icon={faGoogle} className="google-icon" />

                    <span>Login with Google</span>
                  </Button>
                </div>
              ) : null}
            </Col>
            <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              <img src={signinbg} alt="" />
            </Col>
          </Row>
        </Content>
        <Footer>
          <p className="copyright">
            {" "}
            © 2023 Synergy Technology Services Pvt Ltd. All rights reserved.{" "}
          </p>
        </Footer>
      </Layout>
    </>
  );
};

export default SignIn;
