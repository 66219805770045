import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import CryptoJS from "crypto-js";

interface IvState {
    iv: any;
}

const initialState: IvState = {
    iv: CryptoJS.lib.WordArray.random(16),
};


export const ivSlice = createSlice({
  name: "iv",
  initialState,
  reducers: {
    setIv: (state, action: PayloadAction<string[]>) => {
      state.iv = action.payload;
    },
    clearIv: (state) => {
      state.iv = [];
    },
  },
  
});

export const { setIv, clearIv } = ivSlice.actions;
